import { isString } from 'remeda';

import { isError } from 'types/predicates';

import { isSerializedError } from './types';

type ErrorMessage = string | undefined;

export type AuthApiError = {
  non_field_errors: [string];
};

export const isRegularObject = (arg: unknown): arg is { error?: string; data?: AuthApiError } =>
  typeof arg === 'object' && !Array.isArray(arg) && arg !== null;

const getAuthErrorMessage = (err: unknown, fallback = 'Error during sign in'): ErrorMessage => {
  // a classic error or an object with a `message` property
  if (isError(err) || isSerializedError(err)) return err.message;

  // a string
  if (isString(err)) return err;

  // not an error, string or object
  if (!isRegularObject(err)) return fallback;

  // an RTK Query object with a different structure
  if ('error' in err) return err.error;

  // API payload or fallback
  return err.data?.non_field_errors?.[0] ?? fallback;
};

export default getAuthErrorMessage;
