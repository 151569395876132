import React from 'react';

import isPropValid from '@emotion/is-prop-valid';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormHelperText from '@mui/material/FormHelperText';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useOmsSelector } from 'hooks/rtk';

import { ReactComponent as GoogleLogo } from 'assets/google-icon.svg';
import signinBackgroundImg from 'assets/home-hero-skincare-d.jpg';

import Button from 'Components/Button';

import * as TeammatesSelectors from 'dux/teammates/selectors';

import { useGoogleAuthLogin, useProseLogin } from './hooks';

type Props = { isE2E?: boolean };

const StyledDialog = styled(Dialog)`
  text-align: center;
`;

const StyledDialogActions = styled(DialogActions, { shouldForwardProp: isPropValid })<Props>`
  ${({ isE2E }) =>
    isE2E
      ? ''
      : `padding: 20px 24px;
      & button {
        margin: 0;
      }
  `}
`;

const StyledDialogTitle = styled(DialogTitle, { shouldForwardProp: isPropValid })<Props>`
  text-align: left;

  ${({ isE2E }) => (isE2E ? '' : `font-size: 28px;`)}
`;

const GoogleButton = styled(Button)`
  width: 100%;
  height: 50px;
  font-size: 16px;
  border: 1px solid #dedce0;
  color: #3c4043;
  padding: 6px 50px;
  text-align: center;
  justify-content: space-around;
  text-transform: none;
  margin: 0;
`;

const GoogleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 24px;
`;

const BackgroundImage = styled('img')`
  grid-area: hero;
  object-fit: cover;
  height: 100vh;
  width: 110%;
`;

const SigninScene = ({ isE2E }: Props) => {
  const previousUsername: string = useOmsSelector(TeammatesSelectors.getSignedInTeammateUsername);

  const { login: proseLogin, fetchingStatus, errorMessage: proseLoginError } = useProseLogin();
  const { login, errorGoogleMessage } = useGoogleAuthLogin();

  return (
    <>
      {!isE2E && <BackgroundImage alt="Signin background" src={signinBackgroundImg} />}
      <StyledDialog open>
        <StyledDialogTitle isE2E={isE2E}>
          {isE2E ? 'E2E Sign in' : 'Prose Backoffice'}
        </StyledDialogTitle>
        <form onSubmit={proseLogin}>
          {' '}
          <DialogContent>
            <TextField
              variant="standard"
              autoFocus
              fullWidth
              id="username"
              label="Email Address (Username)"
              margin="dense"
              type="email"
              defaultValue={previousUsername}
              required
            />
            <TextField
              variant="standard"
              fullWidth
              id="password"
              label="Password"
              margin="dense"
              type="password"
              required
            />
            {proseLoginError && <FormHelperText error>{proseLoginError}</FormHelperText>}
          </DialogContent>
          <StyledDialogActions isE2E={isE2E}>
            <Button
              color="primary"
              dataTestId="signin"
              type="submit"
              variant="contained"
              isLoading={fetchingStatus === 'pending'}
              size={20}
              loaderColor="secondary"
              data-sentry-unmask
            >
              SignIn
            </Button>
          </StyledDialogActions>
        </form>
        {!isE2E && (
          <GoogleContainer>
            <GoogleButton onClick={login}>
              <GoogleLogo />
              <span>Sign in with Google</span>
            </GoogleButton>
            {errorGoogleMessage && <FormHelperText error>{errorGoogleMessage}</FormHelperText>}
          </GoogleContainer>
        )}
      </StyledDialog>
    </>
  );
};

export default SigninScene;
