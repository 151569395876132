import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { AuthTokens } from 'services/auth-new/types';

import getEnv from 'utils/getEnv';

type GoogleResponse = Record<'token' | 'refresh', string>;
type ProseAuthArgs = Record<'username' | 'password', string>;
type ProseAuthResponse = Record<'access' | 'refresh', string>;

const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: getEnv('REACT_APP_ROOT_URL') }),
  endpoints: builder => ({
    googleSignin: builder.mutation<AuthTokens, string>({
      query: code => {
        throw new Error('Oops');

        return {
        url: '/v1/backoffice/auth/social/jwt-pair/google-oauth2',
        method: 'POST',
        body: { code },
        };
      },
      transformResponse: (response: GoogleResponse) => {
        return { accessToken: response.token, refreshToken: response.refresh };
      },
    }),
    proseSignin: builder.mutation<AuthTokens, ProseAuthArgs>({
      query: ({ username, password }) => ({
        url: '/v1/auth/token/',
        method: 'POST',
        referrerPolicy: 'unsafe-url',
        body: { type: 'staff', username, password },
      }),
      transformResponse: (result: ProseAuthResponse) => {
        return { accessToken: result.access, refreshToken: result.refresh };
      },
    }),
  }),
});

export default authApi;

export const {
  useGoogleSigninMutation,
  useProseSigninMutation,
  util: { resetApiState: resetAuthApiState },
} = authApi;
